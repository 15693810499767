
export const stages = {
    logoReveal: {
        duration:1.3,
        delay:1
    },
    contentReveal: {
        delay:1.4,
        duration:1.2
    }
}

